$sizes: (
        'sm':  768px,
        'md': 991px,
        'lg':  1240px,
        'xl': 1500px
);

@function getPreviousSize($currentSize) {
  $keys: map-keys($sizes);
  $index: index($keys, $currentSize)-1;
  $value: map-values($sizes);
  @return nth($value, $index);
}

@function rem($values) {
  $max: length($values);
  @if $max == 1 { @return #{$values / 16}rem; }
  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, #{nth($values, $i) / 16}rem);
  }
  @return $remValues;
}

@mixin media($minmax, $media) {
  @each $size, $resolution in $sizes {
    @if $media == $size {
      @if ($minmax != "within") {
        @media only screen and (#{$minmax}-width: $resolution) {
          @content;
        }
      } @else {
        @if (index(map-keys($sizes), $media) > 1) {
          @media only screen and (min-width: getPreviousSize($media)+1) and (max-width: $resolution) {
            @content;
          }
        } @else {
          @media only screen and (max-width: $resolution) {
            @content;
          }
        }
      }
    }
  }
}
