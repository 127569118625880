@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";

.container {
    padding-top: rem(37);
}

.categories {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(20);
}

.categoryButton {
    padding: 0.8rem 2rem;
    border: none;
    border-radius: rem(98);
    box-shadow: $multi-shadow;
    white-space: nowrap;
    font-size: 1rem;
    font-weight: $weight-bold;
    color: $black;
    background: $white;
    transition: all 0.2s ease-out;

    &_active {
        background-color: $blue;
        color: $white;
    }
}

@media(hover: hover) and (pointer: fine) {
    .categoryButton {
        &:hover {
            background: $grey-350;
        }
    }
}

.categoryItem {
    display: flex;
}

@include media("within", "sm") {
    .categories {
        justify-content: space-between;
        gap: rem(19);
    }

    .categoryItem {
        flex-basis: calc(50% - 19px / 2);
    }

    .categoryButton {
        width: 100%;
        padding: 0.8rem 0.5rem;
    }
}

// .item {
//   position: relative;
//   list-style: none;
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   justify-content: center;
//   width: 108px;
//   height: 108px;
//   border: 1px solid $grey-200;
//   border-radius: 20px;
//   color: $black;
//   font-weight: 600;
//   line-height: 1rem;
//   padding: 18px 9.5px 18px 9.5px;
//   background: $white;
//   &:hover {
//     cursor: pointer;
//     border: 2px solid $blue;
//     box-shadow: 4px 3px 10px rgba(14, 136, 223, 0.29);
//   }
// }
// .categoryItem {
//   height: 114px;
//   border-radius: 20px;
// }
// .activeItem {
//   border: 2px solid $blue;
//   box-shadow: 4px 3px 10px rgba(14, 136, 223, 0.29);
// }
// .scrollContainer {
//   width: 100%;
// }
// .image {
//   position: absolute;
//   bottom: 10px;
//   width: 100%;
//   height: 100%;
// }
// .image img {
//   width: auto !important;
//   height: auto !important;
//   min-width: 100% !important;
//   min-height: 45px !important;
//   max-width: 100% !important;
//   max-height: 45px !important;
// }
// .text {
//   display: inline-block;
//   margin-top: 8px;
//   position: absolute;
//   top: 65px;
//   width: 90px;
//   text-align: center;
//   word-wrap: break-word;
// }
// @include media("within", "sm") {
//   .categoryItem {
//     margin-right: 10px;
//   }
//   .activeItem {
//     border: 3px solid $blue;
//     box-shadow: 2px 2px 4px rgba(14, 136, 223, 0.29);
//   }
//   .item {
//     width: 76px;
//     height: 76px;
//     padding: 0;
//   }
//   .image {
//     min-width: 42px;
//     min-height: 35px;
//     max-width: 60px;
//     max-height: 40px;
//     bottom: 27px;
//   }
//   .text {
//     font-size: 10px;
//     line-height: 1;
//     word-wrap: break-word;
//     width: 60px;
//     top: 40px;
//   }
//   .categoryItem {
//     height: 76px;
//   }
//   .image img {
//     min-width: 100% !important;
//     min-height: 30px !important;
//     max-width: 100% !important;
//     max-height: 40px !important;
//   }
// }
