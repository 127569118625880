$grey-100: #E2E2E2;
$grey-150: #EDEDED;
$grey-175: #EEEEEE;
$grey-200: #D4D4D4;
$grey-250: #C7C7C7;
$grey-300: #B6B6B6;
$grey-350: #BCBCBC;
$grey-400: #989898;
$grey-500: #869098;
$grey-525: #858585;
$grey-550: #838383;
$grey-575: #5A5A5A;
$grey-600: #4E4E4E;
$grey-700: #2E2E2E;

$white-blue: #5684AC;
$light-blue: #1E5687;
$blue: #276EAC;
$dark-blue: #003E74;
$green: #39AA35;
$pink: #F2C6C3;
$light-pink: #FFF1F0;
$red: #f87777;
$red-dark: #D8352B;
$white: #FFFFFF;
$black: #000000;
$dark: #0B0B0B;
$gold: rgba(#f99500, 0.6);


$multi-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1),
    8px 9px 12px 0px rgba(0, 0, 0, 0.09),
    18px 21px 17px 0px rgba(0, 0, 0, 0.05),
    32px 38px 20px 0px rgba(0, 0, 0, 0.01);

$shadow: 0 0 24px -4px rgba(#101828, 0.08),
    0 0 8px -4px rgba(#101828, 0.03);

$radius: 20;
