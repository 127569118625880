@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}