@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";

.container {
    padding: rem(40) 0;
    margin-bottom: rem(20);
}

.title {
    font-size: clamp(1.5rem, 1.25rem + 1.25vw, 2.375rem);
    font-weight: $weight-black;
    line-height: 1.296;
    color: $dark;
    margin-bottom: rem(15);
}

.subtitle {
    margin-bottom: 1.81em;
    font-size: clamp(1.125rem, 1.0536rem + 0.3571vw, 1.375rem);
    line-height: 1.15;
    color: #8D8787;
}

.prev {
    position: absolute;
    top: 43%;
    left: -20px;
    border-radius: 20px;
    box-shadow: $multi-shadow;
}

.next {
    position: absolute;
    top: 43%;
    right: -20px;
    border-radius: 20px;
    box-shadow: $multi-shadow;
}

.button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    color: $blue;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }

    &_right {
        transform: rotate(180deg);
    }

}

@include media("within", "lg") {
    .prev {
        left: -10px;
    }

    .next {
        right: -10px;
    }
}

@include media("within", "md") {
    .prev {
        left: -10px;
    }

    .next {
        right: -10px;
    }
}

@include media("within", "sm") {
    .button {
        display: none;
    }
}
