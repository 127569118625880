@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.card {
    height: 100%;
    padding: 17px 16px 18px 16px;
    border: 1px solid rgba($color: $black, $alpha: 0.1);
    border-radius: 15px;
    background: $white;

    &:hover {
        border-color: $blue;
        transition: all 0.2s ease-out;
        box-shadow: $shadow;
    }
}

.cardHighlighted,
.cardPremium {
    border: 2px solid $gold;
    padding: 16px 15px 17px 15px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.image {
    position: relative;
    flex-shrink: 0;
    flex-basis: 210px;
    border-radius: 5px;
    overflow: hidden;

    transition: all 0.2s ease-out;
}

.like {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    right: 30px;
    top: 29px;
    width: 24px;
    height: 24px;
    background: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.caption {
    display: flex;
    padding: 0.5rem 0;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $grey-300;
}

.category {
    text-transform: uppercase;
}

.date {
    margin-left: auto;
}

.info {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.price_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 1.25rem;
    line-height: 1.2;
    font-weight: $weight-bold;
    color: $grey-700;

    margin: 0.875rem 0;
}

.address {
    font-size: rem(14);
    line-height: 1.2;
    color: $grey-550;
    margin-bottom: rem(14);
}

.description {
    font-size: rem(15);
    line-height: 1.2;
    color: $grey-525;

    margin-bottom: rem(25);
}

.price {
    font-size: 1.25rem;
    font-weight: $weight-extraBold;
    line-height: 1.25;
    color: $black;
}

.label {
    color: $red-dark;
    border: 1px solid currentColor;
    border-radius: 45px;
    padding: 8px 20px;
    font-size: 0.875rem;
    font-weight: $weight-medium;
    line-height: 1rem;

}

@include media("max", "md") {
    .card {
        padding-bottom: 30px;
    }
}
