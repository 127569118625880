@import "styles/colors";
@import "styles/mixin";
@import "styles/fonts";

.categories {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: rem(39);
    padding-top: rem(40);

    @include media("max", "sm") {
        gap: rem(19);
    }
}


.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
    height: 110px;
    padding: 14px 8px 3px 8px;
    border-radius: 23px;
    border: 3px solid transparent;
    box-shadow: $multi-shadow;
    font-size: rem(14);
    line-height: 1.26;
    font-weight: $weight-semiBold;
    color: $black;
    background-color: $white;

    &:hover {
        background-color: $grey-350;
        img {
            transform: scale(1.1);
        }
    }

    &_active {
        border: 3px solid $blue;
    }
}

.image {
    position: relative;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-bottom: 4px;
    img {
        transition: all 0.3s ease-out ;
    }
}

.text   {
    flex-grow: 1;
    display: flex;
    align-items: center;
    text-align: center;
}
