@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.annons {
  margin: 24px 0;
}
.image {
  position: relative;
  width: 100%;
  padding-top: calc((51.5384615% - 8px) / 2);
  border-radius: 8px;
  overflow: hidden;
}
.title {
  color: $grey-550;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 500;
}

@include media("within", "sm") {
  .wrapper {
    flex-direction: column;
  }
  .image {
    width: 100%;
    padding-top: 51.5384615%;
    border-radius: 8px;
    &:first-child {
      margin-bottom: 12px;
    }
  }
  .title {
    font-size: 10px;
    margin-bottom: 4px;
  }
  .annons {
    margin: 24px 0;
  }
}
