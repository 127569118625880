@import "styles/colors";
@import "styles/fonts";
@import "styles/mixin";

.banner {
  margin: 0 0 33px 0;
  position: relative;
  width: 100%;
  // height: 258px;
  padding-top: 25.1953125%;
}
.banner span {
  border-radius: 8px;
}
.text {
  color: $grey-550;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 8px;
  font-weight: 500;
}
@include media("within", "sm") {
  .banner {
    padding-top: 51.5384615%;
    margin: 0 0 24px 0;
  }
  .text {
    font-size: 10px;
    margin-bottom: 4px;
    margin-top: 24px;
  }
}
