//
// Fonts
//
$main-font:'Gilroy', sans-serif;
$arial: Arial, sans-serif;

//
// Font sizes
//
$font-size-xs: 0.75rem; // 12px
$font-size-s: 0.875rem; // 14px
$font-size-m: 1rem; // 16px
$font-size-l: 1.25rem; // 20px
$font-size-xl: 1.5rem; // 24px
$font-size-xxl: 2rem; // 32px

//
// Weight
//
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semiBold: 600;
$weight-bold: 700;
$weight-bolder: 700; //  todo: remove
$weight-extraBold: 800;//  todo: check
$weight-black: 900;
